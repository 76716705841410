import { FC, useMemo } from 'react';

import { lazyImport } from 'utils';

import { RoutePaths } from 'constants/routes';
import { RouteObject, useRoutes } from 'react-router-dom';

import MainLayout from './MainLayout';
import Login from './login';
import Markordershipped from './markordershipped';
const Settings = lazyImport(() => import('./settings'));

const NotFound = lazyImport(() => import('./notfound'));
const ComingSoon = lazyImport(() => import('./ComingSoon'));

//PLOP_TEMPLATE: ROUTE IMPORTS
const EditProject = lazyImport(() => import('./editproject'));
const ProjectDetails = lazyImport(() => import('./projectdetails'));
const CreateProject = lazyImport(() => import('./createproject'));
const ManageShipOrderAccountInfo = lazyImport(
  () => import('./manageshiporderaccountinfo'),
);
const ItemEdit = lazyImport(() => import('./itemedit'));
const ReviewReturnedPackage = lazyImport(
  () => import('./reviewreturnedpackage'),
);
const ProcessOrder = lazyImport(() => import('./processorder'));
const EditOrder = lazyImport(() => import('./editorder'));
const ItemDetail = lazyImport(() => import('./itemdetail'));
const ClientDetails = lazyImport(() => import('./clientdetails'));
const EmployeeDetails = lazyImport(() => import('./employeedetails'));
const CreateEmployee = lazyImport(() => import('./createemployee'));

const Createwarehouse = lazyImport(() => import('./createwarehouse'));
const CreateExhibit = lazyImport(() => import('./createexhibit'));
const Dashboard = lazyImport(() => import('./dashboard'));
const People = lazyImport(() => import('./people'));
const Globaldata = lazyImport(() => import('./globaldata'));
const Inventory = lazyImport(() => import('./inventory'));
const Projects = lazyImport(() => import('./projects'));

const ItemSkus = lazyImport(() => import('./itemSkus'));

const CreateClient = lazyImport(() => import('./createclient'));
const Employees = lazyImport(() => import('./employees'));

const Exhibit = lazyImport(() => import('./exhibit'));
const CreateItemCategories = lazyImport(() => import('./createitemcategories'));
const ItemCategories = lazyImport(() => import('./itemcategories'));
const Clients = lazyImport(() => import('./clients'));
const ShipOrdersSummary = lazyImport(() => import('./shiporderssummary'));
const ItemCreate = lazyImport(() => import('./itemcreate'));
const ItemList = lazyImport(() => import('./itemlistSummary'));
const CreateShipOrder = lazyImport(() => import('./createshiporder'));

const ShipOrders = lazyImport(() => import('./shiporders'));
const ItemListSummary = lazyImport(() => import('./itemlistSummary'));
const AdjustStock = lazyImport(() => import('./AdjustStock'));
const AppRoutes: FC = () => {
  const routes = useMemo<RouteObject[]>(
    () => [
      {
        path: '/login',
        element: <Login />,
      },
      {
        path: RoutePaths.landing,
        element: <MainLayout />,
        children: [
          //PLOP_TEMPLATE: ROUTE DEFINITIONS
          {
            path: 'projects/edit/:id',
            element: <EditProject />,
          },
          {
            path: 'projects/project-details/:id',
            element: <ProjectDetails />,
          },
          {
            path: 'projects/create',
            element: <CreateProject />,
          },
          {
            path: '/ship-orders/account-info/edit/:id',
            element: <ManageShipOrderAccountInfo />,
          },
          {
            path: 'inventory/items/edit/:id',
            element: <ItemEdit />,
          },
          {
            path: 'ship-orders/ship/:id',
            element: <Markordershipped />,
          },
          {
            path: 'ship-orders/review-returned-package/:id',
            element: <ReviewReturnedPackage />,
          },
          {
            path: 'ship-orders/process/:id',
            element: <ProcessOrder />,
          },
          {
            path: 'ship-orders/edit/:id',
            element: <EditOrder />,
          },
          {
            path: 'item/:id',
            element: <ItemDetail />,
          },
          {
            path: 'client-details',
            element: <ClientDetails />,
          },
          {
            path: 'employee-details',
            element: <EmployeeDetails />,
          },
          {
            path: '/create-employee',
            element: <CreateEmployee />,
          },
          {
            path: '/inventory/item-skus',
            element: <ItemSkus />,
          },
          {
            path: 'settings/create-warehouse',
            element: <Createwarehouse />,
          },
          {
            path: 'people/employees',
            element: <Employees />,
          },
          {
            path: 'exhibit/create-exhibit',
            element: <CreateExhibit />,
          },
          {
            path: 'inventory/adjust-stock/:id',
            element: <AdjustStock />,
          },
          {
            path: 'projects/exhibits',
            element: <Exhibit />,
          },
          {
            path: 'inventory/item-categories/create-category',
            element: <CreateItemCategories />,
          },
          {
            path: 'inventory/item-categories',
            element: <ItemCategories />,
          },
          {
            path: 'people/clients',
            element: <Clients />,
          },
          {
            path: 'people/clients/create-client',
            element: <CreateClient />,
          },
          {
            path: 'ship-orders/summary/:id',
            element: <ShipOrdersSummary />,
          },
          {
            path: 'inventory/create-item/rental-item',
            element: <ItemCreate />,
          },
          {
            path: 'inventory/add-items',
            element: <ItemList />,
          },
          {
            path: 'ship-orders/create',
            element: <CreateShipOrder />,
          },
          {
            path: 'dashboard',
            element: <Dashboard />,
          },
          {
            path: 'people',
            element: <People />,
          },
          {
            path: 'global-data',
            element: <Globaldata />,
          },
          {
            path: 'inventory',
            element: <Inventory />,
          },
          {
            path: 'inventory/items',
            element: <Inventory />,
          },
          {
            path: 'settings',
            element: <Settings />,
          },
          {
            path: 'ship-orders',
            element: <ShipOrders />,
          },
          {
            path: 'projects',
            element: <Projects />,
          },
          { path: '/global-data/vendors', element: <ComingSoon /> },
          { path: '/global-data/events', element: <ComingSoon /> },
          { path: '/global-data/venue', element: <ComingSoon /> },
          { path: '/projects/product-display', element: <ComingSoon /> },

          // should be the last
          { path: '/*', element: <NotFound /> },
        ],
      },
    ],
    [],
  );

  const element = useRoutes(routes);

  return <>{element}</>;
};

export default AppRoutes;
