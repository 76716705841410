import React, { FC } from 'react';

import { HeaderViewProps } from './Header.props';
import { useIsMobile } from 'hooks';
const HeaderView: FC<HeaderViewProps> = ({ children, title }) => {
  const isMobile = useIsMobile();

  return (
    (!isMobile && (
      <div className="navbar bg-base-100 shadow-md sticky top-0 z-10 h-16">
        <div className="flex-1 ml-5 font-semibold text-[20px] text-primary font-secondary">
          {title}
        </div>
        {children}
      </div>
    )) || <></>
  );
};

export default HeaderView;
